import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { Box } from "@mui/material";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { OpenDataPreparationButton } from "@pages/project-details/project-data-management/open-data-preparation-button";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import ShareIcon from "@assets/icons/Share.svg?react";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import { MouseEvent, useCallback, useMemo } from "react";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SphereShareLink } from "@components/common/sphere-share-link";
import { useDialog } from "@components/common/dialog/dialog-provider";
import {
  getSphereViewerUrl,
  OPEN_OTHERS_TARGET_ATTRIBUTE,
  OpenProjectProps,
  trackOpenProject,
} from "@utils/project-utils";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { PublishedData } from "@pages/project-details/project-data-management/published-data/published-data-types";

interface Props {
  /** PublishedData entity */
  publishedData: PublishedData;
}

/** Renders published data actions column */
export function PublishedDataActions({
  publishedData,
}: Props): JSX.Element | null {
  const { projectId } = useDataManagementContext();
  const { trackEvent, trackAsyncEvent } = useTrackEvent();
  const { createDialog } = useDialog();
  const selectedProject = useAppSelector(selectedProjectSelector);

  const sphereViewerUrl = useMemo(() => {
    const dataSet = publishedData.element;

    if (!dataSet) {
      return;
    }

    return getSphereViewerUrl({ projectId, id: dataSet.id }).href;
  }, [projectId, publishedData.element]);

  /** Opens dialog to copy the Viewer deep link */
  const onShare = useCallback((): void => {
    if (!sphereViewerUrl) {
      return;
    }

    trackEvent({ name: DataManagementEvents.sharePublishedData });
    createDialog(
      {
        title: "Share",
        maxWidth: "sm",
        shouldHideActions: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        fullWidth: true,
      },
      <SphereShareLink title="Link" url={sphereViewerUrl} />
    );
  }, [createDialog, sphereViewerUrl, trackEvent]);

  /** Opens the Viewer */
  const onOpen = useCallback(
    (
      event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
      clickType: OpenProjectProps["clickType"]
    ): void => {
      event.stopPropagation();
      trackOpenProject({
        openFrom: "projectDetails-dataManagement-publishedData",
        clickType,
        numberOfMembers: undefined,
        openTarget: ProjectLaunchTarget.sphereViewer,
        ...selectedProject,
        trackAsyncEvent,
      });
    },
    [selectedProject, trackAsyncEvent]
  );

  return (
    <Box
      data-testid="published-data-actions-container"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <OpenDataPreparationButton
        projectId={projectId}
        registrationId={publishedData.id}
        eventName={DataManagementEvents.openDataManagementInspect}
        label="Inspect"
      />
      {sphereViewerUrl && (
        <>
          <SphereActionDivider />

          <SphereTooltip title="Share">
            <FaroIconButton
              buttonSize={ACTION_BTN_SIZE}
              iconSize={ACTION_ICON_SIZE}
              component={ShareIcon}
              onClick={onShare}
            />
          </SphereTooltip>

          <SphereActionDivider />

          <SphereTooltip title="Open">
            <FaroIconButton
              buttonSize={ACTION_BTN_SIZE}
              iconSize={ACTION_ICON_SIZE}
              component={OpenViewerIcon}
              href={sphereViewerUrl}
              target={OPEN_OTHERS_TARGET_ATTRIBUTE}
              onClick={(event) => onOpen(event, "left button click")}
              onAuxClick={(event) => onOpen(event, "middle button click")}
              onContextMenu={(event) => onOpen(event, "open context menu")}
            />
          </SphereTooltip>
        </>
      )}
    </Box>
  );
}
