import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchingStatus } from "@store/store-types";
import {
  addMembersToTeam,
  createTeam,
  deleteTeam,
  fetchTeamDetails,
  fetchTeamMembers,
  fetchTeams,
  removeMembersFromTeam,
  updateTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { TeamState } from "@custom-types/teams-types";
import { removeLocalMemberFromTeam } from "@store/teams/teams-slice-utils";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getMemberId } from "@utils/member-utils";

export const teamAdapter = createEntityAdapter<SphereDashboardAPITypes.ITeam, EntityId>({
  selectId: (team) => team.id,
});

export const initialState: TeamState = {
  ...teamAdapter.getInitialState(),
  status: FetchingStatus.uninitialized,
  selectedTeamId: null,
  fetching: {
    isRemovingTeam: false,
    isRemovingTeamMember: false,
    isFetchingTeamMembers: false,
  },
  selectedTeamMembers: {},
  nextTeamMember: null,
};

const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    resetTeamState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(
        fetchTeams.fulfilled,
        (state, action: PayloadAction<SphereDashboardAPITypes.ITeam[]>) => {
          state.status = FetchingStatus.succeeded;
          teamAdapter.upsertMany(state, action.payload);
        }
      )
      .addCase(fetchTeams.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(createTeam.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.status = FetchingStatus.succeeded;
        teamAdapter.setOne(state, action.payload);
      })
      .addCase(createTeam.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(addMembersToTeam.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(addMembersToTeam.fulfilled, (state, action) => {
        state.status = FetchingStatus.succeeded;
        for (const member of action.payload.data) {
          const id = getMemberId(member.userResponse);
          state.selectedTeamMembers[id] = member;
        }
      })
      .addCase(addMembersToTeam.rejected, (state, action) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(fetchTeamDetails.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(fetchTeamDetails.fulfilled, (state, action) => {
        state.selectedTeamId = action.payload.id;
        state.status = FetchingStatus.succeeded;
        teamAdapter.setOne(state, action.payload);
      })
      .addCase(fetchTeamDetails.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(updateTeamDetails.pending, (state) => {
        state.status = FetchingStatus.pending;
      })
      .addCase(updateTeamDetails.fulfilled, (state, action) => {
        state.selectedTeamId = action.payload.id;
        state.status = FetchingStatus.succeeded;
        teamAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateTeamDetails.rejected, (state) => {
        state.status = FetchingStatus.rejected;
      })

      .addCase(removeMembersFromTeam.pending, (state) => {
        state.fetching.isRemovingTeamMember = true;
      })
      .addCase(removeMembersFromTeam.fulfilled, (state, action) => {
        removeLocalMemberFromTeam(state, action.payload);
        state.fetching.isRemovingTeamMember = false;
      })
      .addCase(removeMembersFromTeam.rejected, (state) => {
        state.fetching.isRemovingTeamMember = false;
      })

      .addCase(deleteTeam.pending, (state) => {
        state.fetching.isRemovingTeam = true;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        teamAdapter.removeOne(state, action.payload);
        state.fetching.isRemovingTeam = false;
      })
      .addCase(deleteTeam.rejected, (state) => {
        state.fetching.isRemovingTeam = false;
      })

      .addCase(fetchTeamMembers.pending, (state) => {
        state.fetching.isFetchingTeamMembers = true;
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.nextTeamMember = action.payload.next ?? null;
        for (const member of action.payload.members) {
          const id = getMemberId(member.userResponse);
          state.selectedTeamMembers[id] = member;
        }
        state.fetching.isFetchingTeamMembers = false;
      })
      .addCase(fetchTeamMembers.rejected, (state) => {
        state.fetching.isFetchingTeamMembers = false;
      });
  },
});

export const { resetTeamState } = teamSlice.actions;

export const teamReducer = teamSlice.reducer;
